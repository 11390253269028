import { INVALID_MOVE } from 'boardgame.io/core';
import { BoardTileType } from './BoardTile';
import { getDefaultMapTiles } from './Map';
import { getGenericPlayerTokens } from './Token';

const sidesOnDice = 4;
const numDice = 4;
const numTokens = 7;

export const Ur = ({

	// game state context
	setup: () => {
		let tiles = getDefaultMapTiles();
		let playerTokens = getGenericPlayerTokens(0, numTokens);
		let opponentTokens = getGenericPlayerTokens(1, numTokens);
		let allTokens = playerTokens.concat(opponentTokens);

		return {
			// array of tokens on the board
			cells: Array(tiles.length).fill(null),
			// array of tiles on the board
			tiles: tiles,
			// highlighted tiles
			highlightedCells: [],
			// array of tokens controlled by the player
			playerTokens: playerTokens,
			// array of tokens controlled by the opponent
			opponentTokens: opponentTokens,
			// array of all tokens on the board
			allTokens: allTokens,
			selectedToken: null,
			// number of points the player has scored
			playerScore: Number(0),
			// number of points the opponent has scored
			opponentScore: Number(0),
			// number of 'moves' the player has rolled
			points: Number(0),
			status: 'Initializing...',
			lastRoll: [],
		}
	},

	minPlayers: 2,

	// possible moves
	moves: {
		rollDice, moveToken, captureToken, scoreGoal,
	},

	// turn definition
	turn: {
		// at least one dice roll per turn
		minMoves: 1,
		// maximum moves is roll, move, extra roll, move, extra roll, move
		// maxMoves: 6,
		// stages in a turn
		stages: {
			// players start each turn by rolling dice to get points
			roll: {
				start: true,
				next: 'move',
				moves: { rollDice }
			},
			// if the player rolls more than 0 points they can put a token on the board
			// or move a token already on the board
			move: {
				moves: { moveToken }
			}
		}
	},

	// game end conditions
	endIf: (G, ctx) => {
		// if a player has removed all of their tokens from the game (scoring 7 points) they win
		// if (G.score[ctx.currentPlayer] === 7) {
		// 	return { winner: ctx.currentPlayer };
		// }
	},

	// Disable undo feature for all the moves in the game
	disableUndo: true,

	// ai: {
	// 	enumerate: (G, ctx) => {
	// 		let moves = [];
	// 		for (let i = 0; i < 9; i++) {
	// 			if (G.cells[i] === null) {
	// 				moves.push({ move: 'clickCell', args: [i] });
	// 			}
	// 		}
	// 		return moves;
	// 	},
	// },
});

function rollDice(G, ctx) {
	// odd or even?
	const roll = ctx.random.Die(sidesOnDice, numDice);
	G.lastRoll = roll;
	for (let i = 0; i < roll.length; i++) {
		// simulate only 2 sides of the die being marked for roll
		if (roll[i] <= 2) {
			G.points++
		}
	}
	console.log('rolled %d points', G.points);
	if (G.points > 0) {
		G.status = 'Rolled ' + G.points + ' points. Select a token to move.';
		ctx.events.endStage();
	} else {
		G.status = 'Rolled ' + G.points + ' points. No available moves.';
		ctx.events.endTurn();
	}
}



// calculate the 'best' move a token can make
// this is obviously subjective based on the board state and some rules
// the initial implementation will prioritize getting tokens as far as possible on the board
// this will generally choose to advance existing tokens over playing new ones
// TODO other 'move generators' in the future, getDefensiveMove, getAggressiveMove, etc.
function getBestMove(G, tokenId) {

}

// when a token reaches a goal tile
// remove the token from the board and game (it cannot be replayed)
// score a point for the controlling player
function scoreGoal(G, ctx, token) {
	token.isScored = true;
	token.isOnBoard = false;
	G.playerTokens.push(token);
}

function captureToken(G, ctx, token) {
	// token reached opposing player's token
	// capture the opposing player's token
	if (token) {
		// remove the token from the board
		G.cells[token.pos] = null;
		token.isOnBoard = false;
		token.pos = -1;
	}
}

// move a token to a tile on the board
function moveToken(G, ctx, tokenId, targetTileId) {
	if (targetTileId === null) {
		console.log('null tile id');
		return INVALID_MOVE;
	}
	if (tokenId === null) {
		// if a token is not selected you can't move nothing
		console.log('you must select a token before moving a token!')
		return INVALID_MOVE;
	}

	console.log('moveToken(): move token %d to tile %d', tokenId, targetTileId);

	let token = G.playerTokens.find(t => t.id === tokenId);
	if (token) {
		let tokenPos = token.pos;
		let targetTile = G.tiles[targetTileId];
		let targetCell = G.cells[targetTileId];
		// check if the target cell is occupied by a token
		if (targetCell !== null) {
			let allTokens = G.playerTokens.concat(G.opponentTokens);
			// check if the target cell is occupied by a token of the same player
			let targetToken = allTokens.find(t => t.pos === targetTileId);
			if (targetToken && targetToken.player === token.player) {
				// if the target cell is occupied by a token of the same player
				// you can't move a token to an occupied cell
				console.log('you can\'t move a token to an occupied cell');
				return INVALID_MOVE;
			} else if (targetToken && targetToken.player !== token.player) {
				// if the target cell is occupied by a token of a different player
				// capture the opposing player's token
				captureToken(G, targetToken);
			}
		} else {
			// if the target cell is empty
			// move the token to the target cell
			if (targetTile.type === BoardTileType.GOAL) {
				// token reached goal tile
				// score a point for the controlling player
				G.score[token.player]++;
				// remove the token from the board
				G.cells[tokenPos] = null;
				// remove the token from the player's token list
				G.playerTokens = G.playerTokens.filter(t => t.id !== tokenId);
				// end turn
				ctx.events.endTurn();
			} else if (targetTile.type === BoardTileType.SPECIAL) {
				// the player rolls again
				// set game state stage to 'roll'
				ctx.events.setStage('roll');
			} else {
				// token moved to a normal tile
				// move the token to the target tile
				G.cells[tokenPos] = null;
				G.cells[targetTileId] = token;
				token.pos = targetTileId;
				// end turn
				ctx.events.endTurn();
			}
		}
	} else {
		console.log('token not found');
	}
}
