import { useEffect, useState } from "react";
import { BoardTile, getTileOfType } from "./BoardTile";

// grid component to display 8x3 grid tiles
export const BoardGrid = ({ board, onBoardClick }) => {
    const [tableRow, setTableRow] = useState([]);
    
    useEffect(() => {
        let tbody = [];
        for (let i = 0; i < 8; i++) {
            const row = [];
            for (let j = 0; j < 3; j++) {
                const key = i * 3 + j;
                row.push(
                    <BoardTile
                        key={i + "-" + j}
                        tile={getTileOfType(key, board[i][j])}
                        onBoardClick={onBoardClick}
                    />
                );
            }
            tbody.push(<tr key={i}>{row}</tr>);
        }
        setTableRow(tbody);
    }, [board, onBoardClick]);

    return (
        <div>
            <table id="board">
                <tbody>{tableRow}</tbody>
            </table>
        </div>
    );
}
