import { Client } from 'boardgame.io/react';
import { Local } from "boardgame.io/multiplayer";

import { Ur } from './components/Ur';
import { UrBoard } from './components/UrBoard';

const UrClient = Client({
	game: Ur,
	board: UrBoard,
	multiplayer: Local()
});

const App = () => (
	<div>
		<UrClient playerID='0'/>
		<UrClient playerID='1'/>
	</div>
);

export default App;