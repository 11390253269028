// 8x3 array of tile type ids
const defaultMap = [[2,1,2],[1,1,1],[1,1,1],[4,2,4],[0,1,0],[0,1,0],[3,1,3],[1,1,1]];

export const maps = {
    DEFAULT: defaultMap
}

export function getDefaultMapTiles() {
    return maps.DEFAULT
}
