import { useEffect, useState } from "react";
import { inaccessibleStyle } from "../styles"
import { InteractiveToken } from "./Token";

// display the tokens for each player
export const TokenTray = ({ player, tokens, selectedToken, selectToken }) => {
	const [tableRow, setTableRow] = useState([]);

	useEffect(() => {
		let tbody = [];
		let playerRow = [];
		// create token display for tokens that are not on the board
		// identified by token.pos < 0 referring to the cell on the board
		for (var token in tokens) {
			playerRow.push(
				<td key={token.id}>
					<div style={inaccessibleStyle}>
						{!token.isOnBoard &&
							<InteractiveToken token={token} onClick={selectToken}/>
						}
					</div>
				</td>
			);
		}
		tbody.push(<tr key={player}>{playerRow}</tr>);
		setTableRow(tbody);
	}, [player, tokens, selectToken]);


	return (
		<div>
			<p style={{ color: '#fff' }}>{player} Tokens</p>
			<table id={player}>
				<tbody>{tableRow}</tbody>
			</table>
		</div>
	);
}