import React, { useEffect, useState } from 'react';
import { TokenTray } from './TokenTray';
import { ScoreDisplay } from './ScoreDisplay';
import { RollDisplay } from './RollDisplay';
import { GameStatusMessage } from './GameStatusMessage';
import { BoardGrid } from './BoardGrid';

export const UrBoard = ({ ctx, G, moves }) => {
	const [selectedToken, setSelectedToken] = useState(null);
	const isGameOver = ctx.gameover;

	const onBoardClick = (tileId) => {
		console.log(tileId);
		let isPlayerTurn = ctx.currentPlayer === ctx.playerID;
		// if it is the current player's turn and the game is not over
		if (isPlayerTurn && !isGameOver) {
			// if a token is selected
			if (selectedToken !== null) {
				let token = G.tokens[selectedToken];
				// if the selected token can move to the clicked tile
				if (getAvailableMoves(G.cells, G.tiles, token, G.points).includes(tileId)) {
					// move the token to the clicked tile
					moves.move(tileId);
				}
				// deselect the token
				setSelectedToken(null);
			} else if (selectedToken === null && G.tiles[tileId].token.player === ctx.playerID) {
				// if no token is selected, select the token at the clicked tile
				setSelectedToken(G.tiles[tileId].token);
			}
		}
	};

	return (
		<div>
			<h1 style={{ color: '#fff' }}>The Royal Game of Ur</h1>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<BoardGrid board={G.tiles} onBoardClick={onBoardClick} />
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<TokenTray player={"Player"} tokens={G.playerTokens}
						selectedToken={selectedToken} selectToken={setSelectedToken}>
					</TokenTray>
					<TokenTray player={"Opponent"} tokens={G.opponentTokens}
						selectedToken={null} selectToken={() => { }}>
					</TokenTray>
					<RollDisplay lastRoll={G.lastRoll} rollDice={moves.rollDice}></RollDisplay>
				</div>
			</div>
			<GameStatusMessage message={G.status}></GameStatusMessage>
			<ScoreDisplay G={G} ctx={ctx}></ScoreDisplay>
		</div>
	);
}

// a token can move equal to the number of points a player has
// return the cells on the board adjacent to the token that a token can a move to
function getAvailableMoves(cells, tiles, token, points) {
	let availableMoves = [];
	let cellsInRange = getCellsWithinDistance(cells, token.pos, points);
	availableMoves = cellsInRange.filter(cell => {
		// probably do something here to check if the cell is empty
		return cell;
	});
	return availableMoves;
}

// function to return the distance between two cells
function getDistance(cell1, cell2) {
	let x1 = cell1.x;
	let y1 = cell1.y;
	let x2 = cell2.x;
	let y2 = cell2.y;
	return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
}

// function to get all cells some distance away from a cell in a grid
function getCellsWithinDistance(cells, tile, distance) {
	let cellsWithinDistance = [];
	for (let i = 0; i < cells.length; i++) {
		if (getDistance(cells[i], tile) <= distance) {
			cellsWithinDistance.push(cells[i]);
		}
	}
	return cellsWithinDistance;
}