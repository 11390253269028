import * as styles from "../styles";
import { InteractiveToken, TokenType } from "./Token";

export const BoardTile = ({ tile, onBoardClick }) => {
	const classes = ["grid-tile"];
	if (tile.isHighlighted) {
		classes.push("highlighted");
	}
	if (tile.isSelected) {
		classes.push("selected");
	}
	if (tile.isAvailable) {
		classes.push("available");
	}

	let tileStyle;
	switch (tile.type) {
		case BoardTileType.INACCESSIBLE:
			tileStyle = styles.inaccessibleStyle;
			break;
		case BoardTileType.GOAL:
			tileStyle = styles.goalStyle;
			break;
		case BoardTileType.START:
			tileStyle = styles.startStyle;
			break;
		case BoardTileType.NORMAL:
			tileStyle = styles.normalStyle;
			break;
		case BoardTileType.SPECIAL:
			tileStyle = styles.specialStyle;
			break;
		default:
			tileStyle = styles.inaccessibleStyle;
			break;
	}

	let tileToken;
	if (tile.token !== null) {
		let tokenStyle;
		if (tile.token.type === TokenType.WHITE) {
			tokenStyle = styles.whiteTokenStyle;
		} else {
			tokenStyle = styles.blackTokenStyle;
		}
		tileToken = (
			<button style={tokenStyle}>
				{tile.token.id + 1}
			</button>
		);
	} else {
		tileToken = null;
	}

	return (
		<td key={tile.id}>
			<div className={classes.join(" ")} style={tileStyle} onClick={() => onBoardClick(tile.id)}>
				{tileToken && (
					<InteractiveToken token={tile.token} onClick={() => { }} />
				)}
			</div>
		</td>
	);
}

export const BoardTileType = {
	INACCESSIBLE: 0,
	NORMAL: 1,
	SPECIAL: 2,
	GOAL: 3,
	START: 4
}

const Tile = {
	id: -1,
	type: BoardTileType.NORMAL,
	token: null,
	isHighlighted: false,
	isSelected: false,
	isAvailable: false
}


export function getTileOfType(id, typeId) {
	let tile = { ...Tile };
	tile.id = id;
	tile.type = typeId;
	return tile;
}