import { useEffect, useState } from "react";
import { whiteTokenStyle, blackTokenStyle } from "../styles"

export const InteractiveToken = ({ token, onClick }) => {
    const [tokenStyle, setTokenStyle] = useState(whiteTokenStyle);

    useEffect(() => {
        if (token.type === TokenType.WHITE) {
            setTokenStyle(whiteTokenStyle);
        } else {
            setTokenStyle(blackTokenStyle);
        }
    }, [token.type]);

    return (
        <button style={tokenStyle} onClick={() => onClick(token.id)}></button>
    );
}

export const Token = {
    id: -1,
    // if the token is on the board this will be the id of the tile it is on
    pos: -1,
    // the player that owns the token
    player: -1,
    // the type/color of the token, this might be redundant with the player
    type: -1,
    // if the token is selected by the player
    isSelected: false,
    // if the token is scored it will have a pos of -1 but should not be displayed in the token tray
    isScored: false
}

export const TokenType = {
    WHITE: 0,
    BLACK: 1
}

// get tokens for generic player, type based on player id
export function getGenericPlayerTokens(playerId, numTokens) {
    const tokens = [];
    for (let i = 0; i < numTokens; i++) {
        tokens.push({
            ...Token,
            id: i,
            player: Number(playerId),
            type: playerId === 0 ? TokenType.WHITE : TokenType.BLACK,
            pos: -1
        });
    }
    return tokens;
}