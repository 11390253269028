import { useEffect, useState } from "react";

export const ScoreDisplay = (G, ctx) => {
    const [playerScore, setPlayerScore] = useState(0);
    const [opponentScore, setOpponentScore] = useState(0);
    const [winner, setWinner] = useState('');

    useEffect(() => {
        let text = '';
        if (ctx.gameover) {
            text = ctx.gameover.winner !== undefined ? (
                <div id="winner">Winner: {ctx.gameover.winner}</div>
            ) : (
                <div id="winner">Draw!</div>
            );
            setWinner(text);
        }
    }, [G, ctx]);

    return (
        <div>
            <p style={{ color: '#fff' }}>Score:</p>
            <p style={{ color: '#fff' }}>Player: {playerScore}</p>
            <p style={{ color: '#fff' }}>Opponent: {opponentScore}</p>

            {winner}
        </div>
    );
}