export const startStyle = {
    backgroundColor: '#235c1a', // green
    color: '#ffffff',
    border: '1px solid #555',
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
};

export const normalStyle = {
    backgroundColor: '#a88f32', // goldish/yellow
    color: '#ffffff',
    border: '1px solid #555',
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
};

export const inaccessibleStyle = {
    backgroundColor: '#7d7c77', // gray
    color: '#ffffff',
    border: '1px solid #555',
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
};

export const specialStyle = {
    backgroundColor: '#1a2b5c', // blue
    color: '#ffffff',
    border: '1px solid #555',
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
};

export const goalStyle = {
    backgroundColor: '#5c261a', // red
    color: '#ffffff',
    border: '1px solid #555',
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
};

export const blackTokenStyle = {
    backgroundColor: '#000000', // black
    color: '#ffffff',
    border: '1px solid #555',
    width: '45px',
    height: '45px',
    lineHeight: '45px',
    textAlign: 'center',
    borderRadius: '50%'
};

export const whiteTokenStyle = {
    backgroundColor: '#ffffff', // white
    color: '#0000000',
    border: '1px solid #555',
    width: '45px',
    height: '45px',
    lineHeight: '45px',
    textAlign: 'center',
    borderRadius: '50%'
};

export function getHighlightedStyle(style) {
    return {
        ...style,
        backgroundColor: '#ff0000',
    };
}